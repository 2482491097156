import React from 'react'
import { useGetSimsQuery } from '../api/apiSlice'
import usePageProvider from '../navbar/pageProvider'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import SimDetailHeader from './SimDetailHeader'

const SimsList = () => {
  const { count, offset } = usePageProvider(10, 'getSims', 'Simulations')
  
  const {
    data: sims,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetSimsQuery({offset ,count})
  	
  let content
 
  if (isLoading) {
    content = <Container><Row><Col>Loading...</Col></Row></Container>
  } else if (isSuccess) {
    content = <Container>
				{sims.sims.map( (sim) => {
					return <SimDetailHeader sim={sim.meta} key={sim.value} />
				})}
				</Container>
  } else if (isError) {
    content = <Container><Row><Col>{error.toString()}</Col></Row></Container>
  }

  return (
    <section className="sims-list">
      {content}
    </section>
  )
}  
 
export default SimsList;