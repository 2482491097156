import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query'

import navbarReducer from '../features/navbar/navbarSlice';
import engineReducer from '../features/engine/engineSlice';
import simReducer from '../features/sims/simSlice';

import auth from "../features/auth/authSlice";
import { apiSlice } from '../features/api/apiSlice'


export const store = configureStore({
  reducer: {
	navbar: navbarReducer,
	engine: engineReducer,
	sim: simReducer,
	[apiSlice.reducerPath]: apiSlice.reducer,
	auth
  },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
      serializableCheck: false,
    }).concat(apiSlice.middleware),
});

setupListeners(store.dispatch)