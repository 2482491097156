//bootstrap
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import FormControl from 'react-bootstrap/FormControl'
import Button from 'react-bootstrap/Button'

import React, { useState } from "react";
import { useLoginMutation } from "../api/apiSlice";
import { setCredentials } from "./authSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

//sha256 for one additional preliminary hashing
import sha256 from 'crypto-js/sha256';

const Login = (props) => {
	const dispatch = useDispatch();
	const push = useNavigate();	
	const [valueUser, setvalueUser] = useState('');
	const [valuePW, setvaluePW] = useState('');
	const [valueCode, setvalueCode] = useState('');
	const [valueIncorrect, setvalueIncorrect] = useState(false);
	
	const [login] = useLoginMutation();
	
	function handleChangeUser(event) {
		setvalueUser(event.target.value);
	}

	function handleChangePW(event) {
		setvaluePW(event.target.value);
	}

	function handleChangeCode(event) {
		setvalueCode(event.target.value);
	}

	const IncorrectLoginMessage = () => {
		if (valueIncorrect) {return ("Invalid Credentials")}						
	}	
	
	function handleSubmit(event) {
	async function handleLogin() {
		try {
		  //const result = await login(JSON.stringify({"username": valueUser, "password": sha256(valuePW).toString()}));
		  const result = await login({"username": valueUser, "password": sha256(valuePW).toString(), "code":valueCode});
			if (result.error) {
		      if (result.error.status === 401) {
				  setvalueIncorrect(true)
			  }
			}
		  if (result.data) {
			dispatch(setCredentials(result.data));
			push("/");
		  }
		} catch (err) {
		  console.log(err);
		}
	}  
	handleLogin()
	event.preventDefault();
	}

	return (
		<div className="App">
			<Container>
				<Row>
					<Col>
					</Col>
					<Col>
						<Form onSubmit={handleSubmit}>
						  <Row>
							<FormControl 
									   placeholder="Username" 
									   value={valueUser} 
									   onChange={handleChangeUser}
                                       isInvalid={valueIncorrect}	/>
						  </Row>
						  <Row>
							<Form.Control type="password" 
										   placeholder="Password"
										   aria-describedby="invalidCred"
										   value={valuePW} 
										   onChange={handleChangePW}
										   isInvalid={valueIncorrect}	/>
							<Form.Control type="password" 
										   placeholder="code"
										   aria-describedby="invalidCred"
										   value={valueCode} 
										   onChange={handleChangeCode}
										   isInvalid={valueIncorrect}	/>
						    <Form.Text id="invalidCred" className="text-danger">
							{IncorrectLoginMessage()}
						  </Form.Text>
						  </Row>					  			  
						  <Row>
							<Button type="submit" >
								Login
							</Button>
						  </Row>
						</Form>
					</Col>
					<Col>
					</Col>
				</Row>
			</Container>
		</div>
	);	
}

export default Login;