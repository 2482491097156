import React, { useState } from 'react'

//victory
import { VictoryChart, VictoryAxis, VictoryCandlestick, VictoryScatter, VictoryBar, Selection, VictoryClipContainer, VictoryZoomContainer, VictoryLabel, VictoryLine} from 'victory'; 

import { time_intervals } from '../../utils/timeIntervals'
import { useSelector, useDispatch } from "react-redux";
import { setLabelPrice, selectLabelPriceByPosId } from '../sims/simSlice.js'

const ChartSymbolDetails = ({ symboldetails, symbolseries, handleDomainChange, /*zoombegin, zoomend,*/ windowwidth, divisor,  bracket, symbol, detail,openorders,closedorders, pos_id }) => {
	//Constants
	const dispatch = useDispatch()
	const windowheight = window.innerHeight; 
	//const main_chart_pad_left = 0	
	const main_chart_width = windowwidth
	const main_chart_height = windowheight * .45	
	const timeIntervalMs = time_intervals[symbolseries[0]?.interv]
	const limits = {
		 low: symbolseries.reduce( (pv,cv) => {
			const l = Math.min( ...[cv.Low, /*cv.SL, cv.CLOSELIMIT,*/ cv.OPENLIMIT].filter(Number.isFinite) ) 
			//const l = cv.Low
			return l < pv ? l : pv
		 }, Infinity )
		,high: symbolseries.reduce( (pv,cv) => {
			const h = Math.max( ...[cv.High, /*cv.SL, cv.CLOSELIMIT,*/ cv.OPENLIMIT].filter(Number.isFinite) ) 
			//const h = cv.High
			return h > pv ? h : pv
		 }, 0 )
		,highvol: symbolseries.reduce( (pv,cv) => ((cv.Volume > pv) ? cv.Volume : pv), 0 )	
		,begin:symbolseries[0]?.Open_time
		,end:symbolseries.slice(-1)[0]?.Open_time
		,zoomedlow:symbolseries.slice(-1)[0]?.Close *.97
		,zoomedhigh:symbolseries.slice(-1)[0]?.Close *1.03
	}
	const normalizePrice = (price) => {
		const r = (price - limits.low) / (limits.high - limits.low) * 100
		return r
	}	

	const denormalizePrice = (y) => {
		return y/100*(limits.high - limits.low) + limits.low
	}
	//const [labelPrice, setLabelPrice]  = useState(null);
	
	const labelPrice = useSelector(selectLabelPriceByPosId(pos_id));
	
	const normalizeVolume = (vol) => {
		const r = vol / limits.highvol * 85
		return r
	}	

	const denormalizeTime = (indx) => {
		const r = indx * timeIntervalMs + limits.begin
		return r
	}

	
	const domx1 = limits.end  + timeIntervalMs ///2
	let domx0 = domx1 - 60*1000*60*(windowwidth/divisor) 
	/*let candleRatio = 3
	if (domx0 < limits.begin) {
		domx0 = limits.begin
		candleRatio = .8
	}*/
	//if (symbol == 'XXBTZEUR') console.log({openorders,closedorders,bracket})
		
	let openordersTS = openorders.filter((d) =>  d.descr_ordertype === 'trailing-stop').map( (order) => {
		return (
			<VictoryLine key={order.ordernumber}
				style={{
				  data: { stroke: "tomato", strokeWidth:1, strokeDasharray:4}
				}}	
				data={[
				{x: order.opentm*1000 ,y: normalizePrice(parseFloat(order.stopprice)) },
				{x:bracket?.find((be) => be.type === "Current")?.ts, y: normalizePrice(parseFloat(order.stopprice)) }
				]}
				/>
		)
	})

	let openordersBUY = openorders.filter((d) =>  d.descr_ordertype === 'limit' && d.descr_type === 'buy').map( (order) => {
		return (
			<VictoryLine key={order.ordernumber}
				style={{
				  data: { stroke: "lightgreen", strokeWidth:1, strokeDasharray:1}
				}}	
				data={[
				{x: order.opentm*1000 ,y: normalizePrice(parseFloat(order.descr_price)) },
				{x:bracket?.find((be) => be.type === "Current")?.ts, y: normalizePrice(parseFloat(order.descr_price)) }
				]}
				/>
		)
	})

	let openordersSELL = openorders.filter((d) =>  d.descr_ordertype === 'limit' && d.descr_type === 'sell').map( (order) => {
		return (
			<VictoryLine key={order.ordernumber}
				style={{
				  data: { stroke: "darkred", strokeWidth:1, strokeDasharray:1}
				}}	
				data={[
				{x: order.opentm*1000 ,y: normalizePrice(parseFloat(order.descr_price)) },
				{x:bracket?.find((be) => be.type === "Current")?.ts, y: normalizePrice(parseFloat(order.descr_price)) }
				]}
				/>
		)
	})

	const getContainerComponent = () => {
		return (
			<VictoryZoomContainer 
				allowZoom={true}
				allowPan={true}
				zoomDimension="x"
				zoomDomain={{x: [domx0, domx1]}}
				onZoomDomainChange={(domain, props) => handleDomainChange(domain, props)}
				minimumZoom={{x: 1000 * 60 * 60 * 24}}
				clipContainerComponent={<VictoryClipContainer clipPadding={{left: 3, right: 3}} />}
			/>
		)
	}
	/*
    const MyLabel = props => {
      const x = props.scale.x(props.x);
      const y = props.scale.y(props.y)
	 // console.log(props)
      return <VictoryLabel {...props} x={x} y={y}/>
    }	
				labels="This is a label"
				labelComponent={
				<MyLabel x={1715747494091} y={normalizePrice(6.4000e-3)} />}	
	
	*/
/*
			<VictoryScatter
				style={{
				  data: { fill: "yellow", fillOpacity: 0.3, strokeWidth:0}
				}}	
				data={data.filter((d) => d.elig1 ).map( (d) => ({x:d.Open_time, y: normalizePrice(d.elig1), symbol:"square"}))}
				size={5}
			/>
			<VictoryScatter
				style={{
				  data: { fill: "grey", fillOpacity: 0.3, strokeWidth:0}
				}}	
				data={data.filter((d) => d.elig2 ).map( (d) => ({x:d.Open_time, y: normalizePrice(d.elig2), symbol:"square"}))}
				size={5}
			/>
			<VictoryScatter
				style={{
				  data: { fill: "orange"}
				}}	
				data={data.filter((d) => d.SL ).map( (d) => ({x:d.Open_time, y: normalizePrice(d.SL), symbol:d.SL > d.High ? "triangleDown" : "triangleUp"}))}
				size={3}
			/>
			<VictoryScatter
				style={{
				  data: { fill: "lightgreen"}
				}}	
				data={symboldetails.hist.filter((d) => d.closelimit ).map( (d, ind) => ({x:denormalizeTime(d.Open_time, y: normalizePrice(d.CLOSELIMIT), symbol:d.CLOSELIMIT > d.High ? "triangleDown" : "triangleUp"}))}
				size={3}
			/>
			<VictoryScatter
				style={{
				  data: { fill: "orange"}
				}}	
				data={data.filter((d) => d.SL ).map( (d) => ({x:d.Open_time, y: normalizePrice(d.SL), symbol:d.SL > d.High ? "triangleDown" : "triangleUp"}))}
				size={3}
			/>
			<VictoryScatter
				style={{
				  data: { fill: "navy", fillOpacity: 0.3, strokeWidth:0}
				}}	
				data={data.filter((d) => d.closedatlimit )}
				size={9}
				x="Open_time"
				y={(d) => normalizePrice(d.closedatlimit) }
			/>
			<VictoryScatter
				style={{
				  data: { fill: "firebrick", fillOpacity: 0.3, strokeWidth:0}
				}}
				data={data.filter((d) => d.OPENLIMIT )}
				size={5}
				x="Open_time"
				y={(d) => normalizePrice(d.OPENLIMIT) }
			/>
			<VictoryScatter
				style={{
				  data: { fill: "firebrick", fillOpacity: 0.3, strokeWidth:0}
				}}	
				data={data.filter((d) => d.openedat )}
				size={9}
				x="Open_time"
				y={(d) => normalizePrice(d.openedat) }
			/>			
			<VictoryScatter
				style={{
				  data: { fill: ({ datum }) => datum.color , fillOpacity: 0.4, strokeWidth:0}
				}}	
				data={bracket.map( (d) => ({x:d.ts, y: normalizePrice(d.price),symbol:"diamond"}))}
				size={6}
			/>	
*/

	//if (symbol === 'XXBTZEUR') console.log(symboldetails)
	
	const [yDomain, setYDomain]  = useState([normalizePrice(limits.low),normalizePrice(limits.high)]);
	const [yDomainZoomed, setYDomainZoomed]  = useState(false);
	return (
		<VictoryChart    
			style={{
				parent: {
				  border: "1px solid #ccc"
				}
			}}
			height={main_chart_height}
			width={main_chart_width}
			padding={{top: 3, bottom: 3, left: 3, right: 3}} 
			containerComponent={ getContainerComponent() }
			scale={{ x: "time", y: "linear" }}	
			domain={ { y:yDomain } }
			events={[{
						childName: 'all',
						target: 'parent',
						eventHandlers: {
							onClick: (evt, props) => {
												const {x: x1,y: y1} = Selection.getSVGEventCoordinates(evt)
												const {y} = Selection.getDataCoordinates( props, props.scale, x1, y1) 
												if (detail.baseAssetPrecision) {
													const price = denormalizePrice(y).toFixed(detail.baseAssetPrecision)
													navigator.clipboard.writeText(price)
													//setLabelPrice(price)
													dispatch(setLabelPrice({pos_id, labelPrice:price}))
													//console.log(  normalizePrice(price))
												}
												if (evt.detail > 1) {
													if (yDomainZoomed) {
														setYDomain([normalizePrice(limits.low),normalizePrice(limits.high)])
														setYDomainZoomed(false)
													} else {
														setYDomain([normalizePrice(limits.zoomedlow),normalizePrice(limits.zoomedhigh)])
														setYDomainZoomed(true)
													}
												}
												//console.log(symboldetails)
												//console.log(bracket)
											}
						}
				    }]}
		>

			<VictoryAxis style={{ 
				axis: {stroke: "transparent"}, 
				ticks: {stroke: "transparent"},
				tickLabels: { fill:"transparent"}
				}}
			/>	
			{/*copy paste price*/}
			{bracket?.find((be) => be.type === "Current")?.ts && 
			<VictoryLine   
				style={{
					  data: { stroke:"wheat"},
					  labels: { fill:"wheat"},
					}}
			  data={[{ x: bracket?.find((be) => be.type === "Current")?.ts - timeIntervalMs * 20, y: normalizePrice(parseFloat(labelPrice)) }, { x: bracket?.find((be) => be.type === "Current")?.ts , y: normalizePrice(parseFloat(labelPrice)) }]}
			  labels={[true]}
			  labelComponent={
				<VictoryLabel
				  text={({datum}) => {if (datum.x === bracket?.find((be) => be.type === "Current")?.ts - timeIntervalMs * 20) return labelPrice}}
				  verticalAnchor="middle"
				/>
			  }
			/>}
			{/* volume */}
			<VictoryBar  
				style={{
					  data: { fillOpacity: 0.1,  fill:"wheat", strokeWidth:0}
					}}
				data={symbolseries}
				x="Open_time"
				y={(d) => normalizeVolume(d.Volume) }
			/>
			<VictoryCandlestick
				  candleColors={{ positive: 'green', negative: 'red' }}
				  style={{
						  data: { strokeWidth: 0.1, stroke: "whitesmoke"}
						}}
				  wickStrokeWidth ={.6}
				  candleWidth={5}
				  data={symbolseries}
				  x="Open_time"
				  open={(d) => normalizePrice(d.Open) }
				  close={(d) => normalizePrice(d.Close) }
				  high={(d) => normalizePrice(d.High) }
				  low={(d) => normalizePrice(d.Low) }
			/>
			{/* sim limits */}
			{symboldetails.all_open_lims && <VictoryScatter
				style={{
				  data: { fill: "grey", fillOpacity: 0.2, strokeWidth:0}
				}}	
				data={symboldetails.all_open_lims.map( (d) => ({x:d.Open_time, y: normalizePrice(d.limit), symbol:"square"}))}
				size={3}
			/>}
			{/* centerline */}
			<VictoryScatter
				style={{
				  data: { fill: "orange", fillOpacity: 0.2, strokeWidth:0}
				}}	
				data={Object.keys(symboldetails.hist).map( (d, ind) => {
					const dp = symboldetails.hist[d]
					return {Open_time: dp.Open_time + timeIntervalMs, 
					        centerLine: dp.centerLine ? dp.centerLine : null
							}
				}).filter((d) => d.centerLine != null ).map( (d) => ({x:d.Open_time, y: normalizePrice(d.centerLine), symbol:"square"}))}
				size={3}
			/>	
			{/* where candlestick shows empty */}
			<VictoryScatter
				style={{
					  data: {fill: "green",fillOpacity: 0.9, strokeWidth:0}
					}}	
				  data={symbolseries.filter((d) => d.Open === d.Close ).map( (d) => ({x:d.Open_time, y: normalizePrice(d.Close), symbol:"circle"}))}
				  size={2}
			/>
			{/* sim trades */}
			{symboldetails?.trades?.length > 0 && <VictoryScatter
				style={{
				  data: { fill: "red", fillOpacity: 0.3, strokeWidth:0}
				}}	
				data={symboldetails?.trades?.filter((d) => d.side === 'buy').map( (d) => ({x:d.ts, y: normalizePrice(d.price), symbol:"triangleUp"}))}
				size={12}
			/>}	
			{/* sim trades */}
			{symboldetails?.trades?.length > 0 && <VictoryScatter
				style={{
				  data: { fill: "green", fillOpacity: 0.3, strokeWidth:0}
				}}	
				data={symboldetails?.trades?.filter((d) => d.side === 'sell').map( (d) => ({x:d.ts, y: normalizePrice(d.price),symbol:"triangleDown"}))}
				size={12}
			/>}
			{openordersTS}
			{openordersBUY}
			{openordersSELL}
			{/* closed orders */}
			{closedorders.length > 0 && <VictoryScatter
				style={{
				  data: { fill: "lightgreen", fillOpacity: 0.8, strokeWidth:0}
				}}	
				data={closedorders.filter((d) => d.descr_type === 'sell' && d.vol_exec > 0).map( (d) => ({x:d.closetm*1000, y: normalizePrice(d.price),symbol:"triangleDown"}))}
				size={4}
			/>}
			{closedorders.length > 0 && <VictoryScatter
				style={{
				  data: { fill: "darkred", strokeWidth:0}
				}}	
				data={closedorders.filter((d) => d.descr_type === 'buy' && d.vol_exec > 0).map( (d) => ({x:d.closetm*1000, y: normalizePrice(d.price),symbol:"triangleUp"}))}
				size={4}
			/>}
			
			
		</VictoryChart>
	)
}

export default ChartSymbolDetails