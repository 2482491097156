import {
  createSlice /*, current */
} from '@reduxjs/toolkit'

import { apiSlice as api } from '../api/apiSlice.js'

const engineSlice = createSlice({
  name: 'engine',
  initialState: {status: 'EMPTY', parameter_obj: {
		H : 27
		,MINGAP: 0.02
		,DVR: 0
		,CONV: 0
		,FIB : [0.618046972,0.381982059,0.236082855,0.145910293]
		,VALDISTR: [1,1.618, 1.618*1.618, 1.618*1.618*1.618, 1.618*1.618*1.618*1.618
		, 1.618*1.618*1.618*1.618*1.618, 1.618*1.618*1.618*1.618*1.618*1.618]
		,VAL: 100
		,SLFACTOR: 0.125 //.04  // .125 ?
		,FEE: .002
		,BOOST: 0
		,minvol:5000
		,markettrend:'B'
		,coinSet:'T'
  }, param_copy_obj: {}, showModal: false, enginePollingInterval: 60 * 60 * 1000},
  reducers: {
   setStatus: (
      state,
      { payload }
    ) => {
		state.status = payload.status		;
    },
	setParameter: (
      state,
      { payload }
    ) => {
		if (['FIB','VALDISTR'].includes(payload.name)) {
			state.parameter_obj[payload.name] = payload.value.split(',')
		} else if (['markettrend','time_interval','exchange', 'quote', 'coinSet'].includes(payload.name)) {
			state.parameter_obj[payload.name] = payload.value
	    } else {
			state.parameter_obj[payload.name] = parseFloat(payload.value)
		}
    },
	setCopyParameters: (
      state,
       { payload } 
    ) => {
		state.param_copy_obj = payload
    },
	pasteCopyParameters: (
      state,
       { payload } 
    ) => {
		for (var key in state.param_copy_obj) {
			let spcok = state.param_copy_obj[key]
			if ( (typeof spcok != 'object' || spcok instanceof Array)
			   &&
			    !(['begin','end','header_id','result', 'closedo', 'rank'].includes(key))
			   ) state.parameter_obj[key] = spcok
		}

    },
	showModal: (
      state,
       { payload } 
    ) => {
		state.showModal = true
    },
	hideModal: (
      state,
       { payload } 
    ) => {
		state.showModal = false
    },
	setEnginePollingInterval: (
      state,
       { payload } 
    ) => {
		state.enginePollingInterval = payload.interval
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      api.endpoints.engineStatus.matchFulfilled,
      (state, { payload }) => {
		for (var key in payload?.result?.result) {			
			let spcok = payload.result.result[key]
			//console.log(key, spcok)
			if ( (typeof spcok != 'object' || spcok instanceof Array)
			   &&
			    !(['begin','end','header_id','result', 'closedo', 'ranking'].includes(key))
			   ) state.parameter_obj[key] = spcok
		}
	  }
    )
  },
})

export default engineSlice.reducer

export const { setStatus, setParameter, setCopyParameters, pasteCopyParameters, showModal, hideModal,  setEnginePollingInterval } = engineSlice.actions

//define selectors
export const selectStatus = (state) => state.engine.status;
export const selectParameters = (state) => state.engine.parameter_obj;
export const selectCopyParameters = (state) => state.engine.param_copy_obj;
export const selectModalState = (state) => state.engine.showModal;
export const selectEnginePollingInterval = (state) => state.engine.enginePollingInterval;




