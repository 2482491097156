import React, { useState }  from 'react'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal';

//FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {   useCoinSettingsQuery, useCoinStatusMutation, useSetCoinSettingsMutation
   } from '../api/apiSlice'

const CoinSettings = ({coin}) => {
	const {
		data: coinSettings,
		isLoading,
		isSuccess, isError, error
	} = useCoinSettingsQuery({exchange: 'kraken',symbol:coin} )	
	const statusicon = coinSettings?.status ? ['fa', 'toggle-on'] : ['fa', 'toggle-off']
	const settingsicon = ['fa', 'sliders']
	
  const [toggleStatus] = useCoinStatusMutation();

  const [persistSettings] = useSetCoinSettingsMutation();
  
  //console.log(coinSettings)
  const [params, setParams]  = useState({});
  const [modalShow, setModalShow]  = useState(false);
	
  
  //functions that call the mutations
  const handleStatusClick = (event) => {
		toggleStatus({symbol:coin,set:!coinSettings.status})
	}
  
  const handleSettingsClick = (event) => {
		setParams(coinSettings.params)
		setModalShow(true)
	}
  
 	const handleClickCloseModal = (event) => {
		persistSettings({symbol: coin, params})
		setModalShow(false)
	} 

	const handleChanges = (event) => {
		const { id, value } = event.target;
		//console.log(id, value)
		const setP = {...params}
		
		if (['FIB','VALDISTR'].includes(id)) {
			setP[id] = value.split(',')
		} else if (['markettrend','time_interval','exchange', 'quote', 'coinSet'].includes(id)) {
			setP[id] = value
	    } else {
			setP[id] = parseFloat(value)
		}
		setParams(setP)
	}  
  
  let content
  if (isLoading) {
    content = <Container><Row><Col>Loading...</Col></Row></Container>
  } else if (isSuccess) {
	  content = <><Container>
					<Row><Col>{coin}</Col>
					     <Col><Button size="sm" variant="secondary" onClick={handleStatusClick}><FontAwesomeIcon icon={statusicon} /></Button></Col>
					     <Col><Button size="sm" variant="secondary" onClick={handleSettingsClick}><FontAwesomeIcon icon={settingsicon} /></Button></Col>
					</Row>
				  </Container>
				  <Modal show={modalShow} onHide={handleClickCloseModal}>
									<Modal.Header closeButton>
										  <Modal.Title>Update Parameters of {coin}</Modal.Title>
									</Modal.Header>
									<Modal.Body>
									  <Form>
										<Form.Label>H</Form.Label>
										  <Form.Control  id="H" defaultValue={params.H} onBlur={handleChanges} />
									    <Form.Label>HD</Form.Label>
										  <Form.Control  id="HD" defaultValue={params.HD} onBlur={handleChanges} />
									    <Form.Label>MINGAP</Form.Label>
										  <Form.Control  id="MINGAP" defaultValue={params.MINGAP} onBlur={handleChanges} />
										<Form.Label>BOOST</Form.Label>
										  <Form.Control  id="BOOST" defaultValue={params.BOOST} onBlur={handleChanges} />
										<Form.Label>VAL</Form.Label>
										  <Form.Control  id="VAL" defaultValue={params.VAL} onBlur={handleChanges} />
										<Form.Label>SLFACTOR</Form.Label>
										  <Form.Control  id="SLFACTOR" defaultValue={params.SLFACTOR} onBlur={handleChanges} />
									  </Form>
									</Modal.Body>
									<Modal.Footer>
									  <Button variant="secondary" onClick={handleClickCloseModal}>
										Close
									  </Button>
									</Modal.Footer>
								</Modal>
				  
				  
				  </>
  } else if (isError) {
    content = <Container><Row><Col>{error.toString()}</Col></Row></Container>
  }

  return (
    <section className="coinsettings">
      {content}
    </section>
  )
}  
 
export default CoinSettings;