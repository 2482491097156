import { createSlice } from "@reduxjs/toolkit";
//import { useLogoutMutation, useGetUserQuery } from "../api/apiSlice";


const matchGetuser = (action) => {
	if (action.meta && action.type === 'api/executeQuery/fulfilled' && action.meta.arg.endpointName === 'getUser' ) return true
	return false
}

const slice = createSlice({
  name: "auth",
  initialState: { user: null } ,
  reducers: {
    setCredentials: (
      state,
      { payload }
    ) => {
		state.user = payload.user;
    },
	logOut: (
      state,
      { payload }
    ) => {
      state.user = null;
    },
	
  },
  extraReducers: (builder) => {
    builder.addMatcher(matchGetuser,
		(state, action) => { // case reducer
			state.user = action.payload.user
    })
  }
});

export const { setCredentials, logOut } = slice.actions;

export default slice.reducer;

export const selectCurrentUser = (state) => state.auth.user ;