import React from 'react'
//import { useSelector, useDispatch } from "react-redux";
//import { time_intervals } from '../../utils/timeIntervals'

import usePageProvider from '../navbar/pageProvider'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import ReportDetails from './ReportDetails'

import { useReportPLQuery } from '../api/apiSlice'

const Report = () => {
	
	const { count, offset } = usePageProvider(10, 'reportPL', 'Week', )
	
	const { data: report, isLoading, isSuccess, isError } = useReportPLQuery({ count, offset })	
	let content
	if (isLoading) {
		content = <Container><Row><Col>Loading...</Col></Row></Container>
	} else if (isSuccess) {
		content =
			  <Container>
				{	report.result.map( (week) => {
					return (<ReportDetails key={week} week={week}/>)
				})}
			  </Container>
  } else if (isError) {
    content = <Container><Row><Col>Error</Col></Row></Container>
  }

  return (
    <section>
      {content}
    </section>
  )
}  
 
export default Report;