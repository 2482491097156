
const formatNice = (n, d) => {
	if (n === 0) return 0
	if (n === null) return 0
	let magn = Math.floor(Math.log(Math.abs(n))/ Math.LN10)
	if (magn > 0) magn++
	if (magn >= 6 || magn <= -2) return n.toExponential(d)
	if (magn < d) return n.toFixed(d-magn)
	if (!n) return undefined
	return n.toFixed()
}

export default formatNice