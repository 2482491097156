import React from 'react'

import usePageProvider from '../navbar/pageProvider'

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import CoinSettings from './coinSettings.js'

import {   useMainSwitchQuery,useCoinSetQuery
   } from '../api/apiSlice'

const Settings = () => {
	const {
		data: mainSwitchStatus
	} = useMainSwitchQuery()	
	const icon = mainSwitchStatus?.DEFAULT ? 'toggle-on' : 'toggle-off'

	const { count, offset } = usePageProvider(10, 'coinSet', 'Coin', { btntoggleMainSwitch: {icon}, 
	})

	const {
		data: coinSet,
    isLoading,
    isSuccess,
    isError,
    error,
		
	} = useCoinSetQuery({ count, offset })	

  let content
  if (isLoading) {
    content = <Container><Row><Col>Loading...</Col></Row></Container>
  } else if (isSuccess) {
	  content = <><Container>
					{coinSet.result.map( (coin) => {
						return <Row key={coin}><Col> <CoinSettings coin={coin} /> </Col></Row>
					})}
				  </Container></>
  } else if (isError) {
    content = <Container><Row><Col>{error.toString()}</Col></Row></Container>
  }

  return (
    <section className="engine">
      {content}
    </section>
  )
}  
 
export default Settings;