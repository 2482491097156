import React, { useState, useEffect } from 'react'
import { useGetSymbolDetailsQuery, /*useGetSymbolSeriesQuery,*/ useOpenPosMutation, /*usePositionQuery, */useHoldPosMutation, useUpdateActionMutation, useCancelPreMutation, usePhaseOutMutation } from '../api/apiSlice'

import { useSelector, useDispatch } from "react-redux";
//import { selectPollingIntervalByPosSymbol, setPositionPollingInterval } from './simSlice'
import { selectLabelPriceByPosId } from '../sims/simSlice.js'

//import { time_intervals } from '../../utils/timeIntervals'
import niceFormat from '../../utils/niceFormat.js'
//import tsFormat from '../../utils/tsFormat.js'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button'
import ProgressBar from 'react-bootstrap/ProgressBar';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal';
import Badge from 'react-bootstrap/Badge';
	
import ChartSymbolDetails from '../graphs/ChartSymbolDetails'

//FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const SymbolDetails = ( { detail, HeaderId, simheader, ts } ) => {
	const dispatch = useDispatch()
	const {
		data: symboldetails,
		//isLoading,
		isFetching: sdIsFetching,
		isSuccess: sdIsSuccess,
		//isError,
		//error,
	} = useGetSymbolDetailsQuery //({header_id:HeaderId, /*symbol:detail.symbol,*/ detail})	
	     ({header_id:HeaderId, symbol:detail.symbol,exchange:simheader.exchange, interval:simheader.time_interval,begin:simheader.begin, end:simheader.end})
	/*const {
		data: symbolseries,
		isFetching: ssIsFetching,
		isSuccess: ssIsSuccess,
	} = useGetSymbolSeriesQuery({exchange:simheader.exchange, symbol:detail.symbol, interval:simheader.time_interval, begin:simheader.begin, end:simheader.end})	*/

	const symbolseries = symboldetails?.series
	
	//const pollingParams = useSelector(selectPollingIntervalByPosSymbol(detail.symbol));
	//console.log(symboldetails)
	/*const {
		data: position,
		isFetching: pIsFetching,
		isSuccess: pIsSuccess,		
	} = usePositionQuery({exchange:simheader.exchange, symbol:detail.symbol}/*, {
		pollingInterval: pollingParams.pollingInterval,
		skipPollingIfUnfocused: false,
	})*/
   
	const position = symboldetails?.position
	
	 //console.log(position)
	
	/*useEffect( () => {
		if (pIsFetching === true && pollingParams.pollingAttempts < 5 && pollingParams.pollingInterval === 1000) {
			dispatch(setPositionPollingInterval({ symbol: detail.symbol, interval: 1000, attempts: pollingParams.pollingAttempts + 1 }))
		} else if (pIsFetching === true && pollingParams.pollingAttempts >= 5 && pollingParams.pollingInterval === 1000) {
			dispatch(setPositionPollingInterval({ symbol: detail.symbol, interval: 15 * 60 * 1000, attempts: 0 }))
		}
	}, [pIsFetching, detail, dispatch,pollingParams ])*/

	const windowwidth = window.innerWidth; 
	var divisor
	const number_of_hours = 64 * 5
	if (windowwidth/number_of_hours < 5) {
		divisor = 6
	} else {
		divisor = windowwidth/number_of_hours
	}
	//console.log(symboldetails)
	//console.log(position)
	const [zoombegin, setzoombegin]  = useState(0);
	const [zoomend, setzoomend]  = useState(0);
	const [selectedTrades, setSelectedTrades]  = useState([]);
	//const [bracket, setbracket] = useState([])
	const bracket = symboldetails?.bracket
	//console.log(bracket)
	const d = 4
	const openPosPresent = position?.status === 'none' ? false : true
	//const [openPosPresent, setOpenPosPresent]  = useState(false);
	//const [absPosAmt, setAbsPosAmt]  = useState(null);
	const absPosAmt = position?.p_l?.P_L || ( position?.status === 'pre-open' ? 0 : '...')
	//const [posStatus, setPosStatus]  = useState(null);
	let posStatus
	if ( position && position?.actions?.findIndex(a => ['O','F'].includes( a.status?.slice(-2,-1) ) || ['O','F'].includes( a.status?.slice(-1) ) ) > -1 ) {
		posStatus = <Badge bg="warning">{position.status}</Badge>
	} else if (position && position.hold) {
		posStatus = <Badge bg="danger">{position.status}</Badge>
	} else {
		posStatus = <Badge bg="secondary">{position?.status || '...'}</Badge>
	}
	//const [lastClose, setLastClose]  = useState(null);
	// const faCaretUp = ['fa', 'caret-up']
	// const faCaretDown = ['fa', 'caret-down']
	// const faArrowsUpToLine = ['fa', 'arrows-up-to-line']
	// const faArrowsDownToLine = ['fa', 'arrows-down-to-line']
	
	// const faArrowsToCircle = ['fa', 'arrows-to-circle']
	const clock = ['fa', 'clock']
	const hashtag = ['fa', 'hashtag']
	const euroSign = ['fa', 'euro-sign']
	const moneyCheck = ['fa', 'money-check-dollar']
	const xmark = ['fa', 'xmark']
	const listIcon = ['fa', 'list']
	const pauseIcon = ['fa', 'pause']
	const arrowTrendDown = ['fa', 'arrow-trend-down']

	const labelPrice = useSelector(selectLabelPriceByPosId(position?.id));

	useEffect(() => {
		const faTag = ['fa', 'tag']
		if ( sdIsSuccess && !sdIsFetching ) {
			//below remain
			const zBegin = symbolseries.slice(-1)[0]?.Open_time - 60*1000*60*(windowwidth/divisor)
			const zEnd = symbolseries.slice(-1)[0]?.Open_time
			setzoombegin(zBegin) 
			setzoomend(zEnd)
			setSelectedTrades(symboldetails?.symboldetails.summTrades.filter( (trade) => trade.ts <= zEnd && trade.ts >= zBegin))
			//end remain
			//let l_lastClose = symbolseries.series[symbolseries.series.length-1].Close
			//setLastClose(l_lastClose)
			/*let l_bracketsetter = []
			if (position.openPosPresent) {
				setOpenPosPresent(true)
				l_bracketsetter = [...position.bracketsetter]
				l_bracketsetter.push({icon:faTag,price:l_lastClose, type:'Current', dist: ((l_lastClose/position.result.centerLine - 1)*100).toFixed(2), amt:null, erroraction:null, lastaction:null, exec:null, profit:null, color:'red', ts: zEnd })
				let l_status = position?.p_l?.status
				let l_status_desc
				switch (l_status) {
					case 'void':
						l_status_desc = 'void'
						break
					case 'close':
						l_status_desc = 'close'
						break
					case 'pre-open':
						l_status_desc = 'pre'
						break
					case 'open':
						l_status_desc = 'open'
						break
					case 'xcenter':
						l_status_desc = 'x/c'
						break
					case 'phaseout':
						l_status_desc = 'out'
						break
					default:
						l_status_desc = 'new'
						break
				}
				if ( 
					position.actions.findIndex(a => ['O','F'].includes( a.status?.slice(-2,-1) )  
					                                          || ['O','F'].includes( a.status?.slice(-1) ) ) > -1  
					) {
					setPosStatus(<Badge bg="warning">{l_status_desc}</Badge>)
				} else if (position.result.hold) {
					setPosStatus(<Badge bg="danger">{l_status_desc}</Badge>)
				} else {
					setPosStatus(<Badge bg="secondary">{l_status_desc}</Badge>)
				}
				setAbsPosAmt(position?.p_l?.P_L || 0)
			} else  {
				l_bracketsetter = [...symboldetails.bracketsetter]
				l_bracketsetter.push({icon:faTag,price:l_lastClose, type:'Current', dist: ((l_lastClose/detail.centerLine - 1)*100).toFixed(2), amt:null, erroraction:null, lastaction:null, exec:null, profit:null, color:'red', ts: zEnd })
			}*/
			/*const totVol = position.details.filter( (posdetail) => posdetail.direction === 'open').reduce( (pv,cv) => pv + parseFloat(cv.vol_exec || 0), 0 ) 
			let avgCost, avgRevenue, absPos
			if (totVol === 0) {
				avgCost = 0 
				absPos = 0
				avgRevenue = 0
			} else {
				avgCost = position.details.filter( (posdetail) => posdetail.direction === 'open').reduce( (pv,cv) => pv + parseFloat(cv.cost || 0) + parseFloat(cv.fee || 0), 0 ) / totVol
				absPos = position.details.filter( (posdetail) => posdetail.direction === 'close').map( (posdetail) => {
					let profit
					if (posdetail.type === 'long') {
						profit = (Math.round((lastClose/avgCost - 1) * 1000)/10)
					} else {
						profit = (Math.round((avgCost/lastClose - 1) * 1000)/10)
					}
					return {...posdetail, profit}
				}).reduce( (pv,cv) => pv + parseFloat(cv.profit) * parseFloat(cv.vol || 0 ), 0 ) 
			}
			
			const l2_bracketsetter = l_bracketsetter.map( (posdetail) => {
				if (posdetail.direction === 'close') {
					let profit
					if (posdetail.type === 'long') {
						profit = (Math.round((lastClose/avgCost - 1) * 1000)/10).toFixed(1)
					} else {
						profit = (Math.round((avgCost/lastClose - 1) * 1000)/10).toFixed(1)
					}
					posdetail.profit = profit
				}
				return {...posdetail}
			})
			
			l_bracketsetter.sort((a,b) => b.price - a.price)
			setbracket(l_bracketsetter)
			*/
		}
		
	}, [sdIsSuccess,sdIsFetching,divisor,symboldetails, symbolseries, windowwidth
	]);

	const [openPos, 
		   { isLoading: openPosIsLoading,
             isSuccess: openPosIsSuccess,
			 reset: openPosReset } ] = useOpenPosMutation();

	const handleDomainChange = (domain, props) => {
		setzoombegin(domain.x[0])
		setzoomend(domain.x[1])
		setSelectedTrades(symboldetails?.symboldetails.summTrades.filter( (trade) => trade.ts <= domain.x[1] && trade.ts >= domain.x[0]))
	}

	const [openPosResult, setOpenPosResult]  = useState('');
	const sendOpenPos = async () => {
		const r = await openPos({symbol:detail.symbol, exchange:simheader.exchange, centerLine: detail.centerLine, type:detail.type /*, params:activeParams*/})
		if (r.data.postresult.error.length > 0) {
			openPosReset()
			setOpenPosResult(r.data.postresult.error)
		} else {
			//dispatch(setPositionPollingInterval({ symbol: detail.symbol, interval: 1000, attempts: 0 }))
			setOpenPosResult('Succes')
		}
	}

	const [cancelPre, 
		   { isLoading: cancelPreIsLoading,
             isSuccess: cancelPreIsSuccess } ] = useCancelPreMutation();
			 
	const [phaseOut, 
		   { isLoading: phaseOutIsLoading,
             isSuccess: phaseOutIsSuccess } ] = usePhaseOutMutation();

	const cancelPreOpen = (event) => {
		cancelPre({pos_id:position.id , symbol:position.symbol, exchange: position.exchange })
	}

	const phaseOutClick = (event) => {
		phaseOut({pos_id:position.id , symbol:position.symbol, exchange: position.exchange, price:parseFloat(labelPrice) })
		setModalPOShow(false)
	}

	const [updateAction ] = useUpdateActionMutation();

	const [focusedActionInit, setFocusedActionInit]  = useState({qty:null, actionStatus:null, pdStatus:null, action_id:null});
	const [focusedAction, setFocusedAction]  = useState({qty:null, actionStatus:null, pdStatus:null, action_id:null});
	const [modalShow, setModalShow]  = useState(false);
	
	const handleChanges = (event) => {
		const { id, value } = event.target;
		//console.log(id, value)
		const setFA = {...focusedAction}
		setFA[id] = value
		setFocusedAction(setFA)
	}

	const handleClickOpenModal = (event) => {
		const { id } = event.target;
		const l_obj = position.actions.find((a) => a.id === parseInt(id))
		const obj = {qty:l_obj.qty, actionStatus:l_obj.actionStatus, pdStatus:l_obj.pdStatus, action_id:l_obj.id}
		setFocusedActionInit(obj)
		setFocusedAction(obj)
		setModalShow(true)
	}
	
	const handleClickCloseModal = (event) => {
		if (!(focusedAction.qty === focusedActionInit.qty && focusedAction.actionStatus === focusedActionInit.actionStatus && focusedAction.pdStatus === focusedActionInit.pdStatus)) {
			updateAction(focusedAction)
			//req.body.pdStatus, req.body.actionStatus, req.body.qty, req.body.action_id
		}
		setModalShow(false)
	}

	const [modalPOShow, setModalPOShow]  = useState(false);
	
	const handleClickOpenPOModal = (event) => {
		setModalPOShow(true)
	}
	
	const handleClickClosePOModal = (event) => {
		setModalPOShow(false)
	}
	
	let actionTableContents =  <>
		{sdIsSuccess && position?.actions?.map( (pricelevel) => {
			if (['F','O'].includes(pricelevel.pdStatus) ||  ['F','O'].includes(pricelevel.actionStatus) ) {
			return (<React.Fragment key={pricelevel.id}><tr>
				  <td><FontAwesomeIcon icon={pricelevel.icon} /></td>
				  <td>{pricelevel.ts}</td>
				  <td>{niceFormat(pricelevel.price,d)}</td>
				  <td>{pricelevel.dist}</td>
				  <td>{pricelevel.action}</td>
				  <td>{pricelevel.amt}</td>
				  <td><Button id={pricelevel.id} variant="secondary" onClick={handleClickOpenModal}>
						{pricelevel.status}
					  </Button>
				  </td>
				</tr>
				<tr><td colSpan="7">{pricelevel.error}</td></tr></React.Fragment>
			) } else {
				return (<React.Fragment key={pricelevel.id}><tr>
				  <td><FontAwesomeIcon icon={pricelevel.icon} /></td>
				  <td>{pricelevel.ts}</td>
				  <td>{niceFormat(pricelevel.price,d)}</td>
				  <td>{pricelevel.dist}</td>
				  <td>{pricelevel.action}</td>
				  <td>{pricelevel.amt}</td>
				  <td>{pricelevel.status}</td>
				</tr>
				</React.Fragment>
			)}
		})}
	</>

	const simTradesTable = <Table striped bordered hover size="sm" className='details_table'>
										  <thead>
											<tr>
											  <th><FontAwesomeIcon icon={hashtag} /></th>
											  <th><FontAwesomeIcon icon={clock} /></th>
											  <th>Trade</th>
											  <th><FontAwesomeIcon icon={euroSign} /></th>
											</tr>
										  </thead>
										  <tbody>
					{selectedTrades.map( (trade) => {
						return (<tr key={trade.seq} >
								  <td>{trade.seq}</td>
								  <td>{(new Date(trade.ts)).getHours()}</td>
									<td>{niceFormat(trade.sqty,d) + ' x ' + niceFormat(trade.price,d) + ' = ' + niceFormat(trade.val,2) + " (" + niceFormat(trade.qoh,d) + ")" }</td>
								  <td>{niceFormat(trade.saldo,2)}</td>
								</tr>)
					})}
				 </tbody>
				</Table>

	const engineTableSection =  HeaderId === 'NGINE' ?
			<Row>
				<Col md="6">
					<Tabs
					  defaultActiveKey="bracket"
					  id="position-details"
					>
						<Tab key="bracket" eventKey="bracket" title="Bracket">
							<Row>
							  <Table striped bordered hover size="sm">
								  <thead>
									<tr>
									  <th></th>
									  <th>P</th>
									  <th>%</th>
									  <th>Actions</th>
									  <th>Amt</th>
									  <th>Exec</th>
									</tr>
								  </thead>
								<tbody>
									{bracket?.map( (pricelevel) => {
										return (<tr key={niceFormat(pricelevel.price,d) + pricelevel.type + pricelevel.lastaction} >
											  <td><FontAwesomeIcon icon={pricelevel.icon} /></td>
											  <td  className={pricelevel.color} >{niceFormat(pricelevel.price,d)}</td>
											  <td>{pricelevel.dist}</td>
											  <td>{pricelevel.lastaction}<span className="red">{pricelevel.erroraction}</span></td>
											  <td>{pricelevel.amt}</td>
											  <td><ProgressBar now={pricelevel.exec * 100} /></td>
											</tr>)
									})}
								</tbody>
							  </Table>
							</Row>
						</Tab>
						<Tab key="actions" eventKey="actions" title="Actions">
							<Row>
							  <Table striped bordered hover size="sm">
								  <thead>
									<tr key="0">
									  <th></th>
									  <th><FontAwesomeIcon icon={clock} /></th>
									  <th>P</th>
									  <th>%</th>
									  <th>Action</th>
									  <th>Amt</th>
									  <th>Status</th>
									</tr>
								  </thead>
								<tbody>
									{actionTableContents}
								</tbody>
							  </Table>
							  <Modal show={modalShow} onHide={handleClickCloseModal}>
									<Modal.Header closeButton>
										  <Modal.Title>Update Action</Modal.Title>
									</Modal.Header>
									<Modal.Body>
									  <Row>{focusedAction.action_id}
									  <Form>
										<Form.Label>Qty</Form.Label>
										  <Form.Control  id={'qty'} defaultValue={focusedAction.qty} onBlur={handleChanges} />
										<Form.Label>Action Status</Form.Label>
										  <Form.Control  id={'actionStatus'} defaultValue={focusedAction.actionStatus} onBlur={handleChanges} />
										<Form.Label>Detail Status</Form.Label>
										  <Form.Control  id={'pdStatus'} defaultValue={focusedAction.pdStatus} onBlur={handleChanges} />
									  </Form>
									  </Row> 
									</Modal.Body>
									<Modal.Footer>
									  <Button variant="secondary" onClick={handleClickCloseModal}>
										Close
									  </Button>
									</Modal.Footer>
								</Modal>
							</Row>
						</Tab>
						<Tab key="trades" eventKey="trades" title="SimTrades">
							<Row>
							{simTradesTable}
							</Row>
						</Tab>
					</Tabs>
				</Col>
				</Row>  : <></>
	
	const [showTrades, setShowTrades]  = useState('hidden');
	
	const toggleShowTrades = (event) => {
		if (showTrades === 'hidden') setShowTrades('')
		if (showTrades === '') setShowTrades('hidden')
	}

	const [holdPos, { isFetching: holdPosIsFetching } ] = useHoldPosMutation();
	
	const toggleHold = (event) => {
		holdPos({pos_id:position.id , symbol:position.symbol , hold: !(position.hold)})
	}

	let statusDependentButton
	if (!openPosPresent) {
		statusDependentButton = <Button onClick={sendOpenPos} disabled={openPosIsLoading || openPosIsSuccess || openPosPresent || sdIsFetching}  variant="secondary"><FontAwesomeIcon icon={moneyCheck} /></Button>
	} else if ( position?.p_l?.status === 'pre-open' ) {
		statusDependentButton = <Button onClick={cancelPreOpen} disabled={sdIsFetching}  variant="secondary"><FontAwesomeIcon icon={xmark} /></Button>
	} else if (['open', 'xcenter'].includes(position?.p_l?.status) ) {
		statusDependentButton = 
			<><Button onClick={handleClickOpenPOModal} disabled={!labelPrice} variant="secondary">
				<FontAwesomeIcon icon={arrowTrendDown} />
			</Button>
			<Modal show={modalPOShow} onHide={handleClickClosePOModal}>
				<Modal.Header>
					  <Modal.Title>Initiate PhaseOut</Modal.Title>
				</Modal.Header>
				<Modal.Body>
				  Phase Out {detail.symbol} - price {labelPrice}
				</Modal.Body>
				<Modal.Footer>
				  <Button variant="secondary" onClick={handleClickClosePOModal}>
					Cancel
				  </Button>
				  <Button variant="secondary" onClick={phaseOutClick}>
					OK
				  </Button>
				</Modal.Footer>
			</Modal></>
			
			
			
	}

	const engineConsole =  HeaderId === 'NGINE' ?
	<><Row>
		<Col xs='2' sm='1' className="xs-2">
			<Button onClick={toggleShowTrades} variant="secondary"><FontAwesomeIcon icon={listIcon} /></Button>
		</Col>
		<Col xs='2' sm='1'>
			<Button onClick={toggleHold} variant="outline-danger" disabled={ holdPosIsFetching || !openPosPresent} active={position?.hold}><FontAwesomeIcon icon={pauseIcon} /></Button>
		</Col>
		<Col xs='2' sm='1'>
			{statusDependentButton}
		</Col>
		<Col xs='4' sm='4'>	{openPosResult}
		</Col>
	</Row>
	<Row className={showTrades}>
		{engineTableSection}
	</Row>
	</>   : <>{simTradesTable}</>


	const expandIcon = ['fa', 'chevron-down']

	const [showDetails, setShowDetails]  = useState('hidden');
	
	const toggleShowDetails = (event) => {
		if (showDetails === 'hidden') setShowDetails('')
		if (showDetails === '') setShowDetails('hidden')
	}

	return (
		<>
		<Row className="py-2 px-0">
			<Col xs="3">{detail.symbol}</Col>
			{HeaderId === 'NGINE' ? 
			<><Col xs="2">{absPosAmt}</Col>
			  <Col xs="2">{detail.type}</Col>
			  <Col xs="3">{posStatus}</Col>
			  
			</> : <Col></Col>}
			<Col xs="1" className="text-end"><Button size="sm" onClick={toggleShowDetails} variant="secondary"><FontAwesomeIcon icon={expandIcon} /></Button></Col>
		</Row>
		<section className={showDetails}>
		<Row>
			<Col>{sdIsSuccess && <ChartSymbolDetails symboldetails={symboldetails.symboldetails} symbolseries={symbolseries} handleDomainChange={handleDomainChange} zoombegin={zoombegin} zoomend={zoomend} windowwidth={windowwidth} divisor={divisor} bracket={bracket} symbol={detail.symbol} detail={detail} openorders={symboldetails?.openorders} closedorders={symboldetails?.closedorders} pos_id={position?.id} />}</Col>
		</Row>
		{engineConsole}
		</section>
		</>
	)
}

export default SymbolDetails