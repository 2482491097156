
import { useEffect } from 'react'

import { selectPageSetById, setCurrentPageSet } from './navbarSlice'
import { useSelector, useDispatch } from "react-redux";

const usePageProvider = (count, endpoint, title, contextButtons) => {
	const pages = useSelector((state) => selectPageSetById(state, endpoint)); 
	const offset = parseInt(pages?.currentPage) || 0
	const dispatch = useDispatch()	
	useEffect(() => {
		dispatch(setCurrentPageSet({newPageSet:endpoint, title, contextButtons}))	
	}, [pages, contextButtons, dispatch, endpoint, title]);
	return {count, offset, pages}
}

export default usePageProvider;