import React, { useState }  from 'react'
import usePageProvider from '../navbar/pageProvider'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { usePositionsQuery } from '../api/apiSlice'
import SymbolDetails from '../sims/SymbolDetails'
import Table from 'react-bootstrap/Table';

const Positions = () => {
	const { count, offset } = usePageProvider(10, 'positions', 'Coin', { })

	const { data: positions, isLoading, isSuccess, isError } = usePositionsQuery({offset ,count, exchange:'kraken' }, )	
	
	const dummyHeader = {begin: positions?.engineData?.begin, end: Date.now(), time_interval: '1h', exchange:'kraken'}
	let content

	if (isLoading ) {
	content = <Container><Row><Col>Loading...</Col></Row></Container>
	} else if (isSuccess) {
		content = 
		<Container>
			<Row>
				<Table striped bordered hover size="sm">
					<thead>
						<tr>
						  <th>P/L</th>
						  <th>Pre-Open</th>
						  <th>Open</th>
						  <th>Phase-Out</th>
						  <th>XCenter</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>{positions?.P_L?.P_L}</td>
							<td>{positions.result.filter((p) => p.status === 'pre-open').length}</td>
							<td>{positions.result.filter((p) => p.status === 'open').length}</td>
							<td>{positions.result.filter((p) => p.status === 'phaseout').length}</td>
							<td>{positions.result.filter((p) => p.status === 'xcenter').length}</td>
						</tr>
					</tbody>
				</Table>
			</Row>
			<Row>
				{	positions.result.map( (detail) => {
					return (<SymbolDetails detail={detail} HeaderId={'NGINE'} key={detail.symbol} simheader={dummyHeader} />)
				})}
			</Row>
		</Container>	
  } else if (isError) {
    content = <Container><Row><Col>Error</Col></Row></Container>
  }

  return (
    <section>
      {content}
    </section>
  )
}  
 
export default Positions;