import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { logOut } from "../auth/authSlice";
import { useLogoutMutation } from "../api/apiSlice";

import Button from 'react-bootstrap/Button'

export default function Logout() {
  const dispatch = useDispatch();
  const [logout] = useLogoutMutation();
  const push = useNavigate();	

    function handleLogout(event) {
		async function hLogOut() {
		  await logout();
		  dispatch(logOut())
		  push("/login");
		}  
		hLogOut();  
		event.preventDefault();
	  }
  
  
  return (
	<Button onClick={handleLogout} variant="secondary">Logout</Button>
		
  );
}
