import {
  createSlice,
  createEntityAdapter,
  isAllOf
} from '@reduxjs/toolkit'


const pagesetAdapter = createEntityAdapter();
//const pageAdapter = createEntityAdapter({selectId: (entity) => entity.offset});

function haspages(action) {
	if (action?.payload && 'pages' in action?.payload) return true
	return false
}

const navbarSlice = createSlice({
  name: 'navbar',
  initialState: {pageSets: pagesetAdapter.getInitialState(), currentPageSet:'getSims', title: "Simulations", contextButtons: {}},
  reducers: {
   setCurrentPageSet: (
      state,
      { payload }
    ) => {
		state.currentPageSet = payload.newPageSet
		state.title = payload.title
		state.contextButtons = payload?.contextButtons || {}
		;
    },
	setCurrentPage: (
      state,
      { payload }
    ) => {
		state.pageSets.entities[payload.pageSet].currentPage = payload.newPage;
    }
  },
  extraReducers(builder) {
    builder.addMatcher(isAllOf(
		(action) => action.type.endsWith("/fulfilled"), // matcher function
			haspages 
			),
		(state, action) => { // case reducer
			const pagesetId = action.meta.arg.endpointName
			const pagesetEntry = {id: pagesetId, currentPage: action.meta.arg.originalArgs.offset, pages: action.payload.pages}
			pagesetAdapter.upsertOne(state.pageSets, pagesetEntry)	
	})
  },
})

export default navbarSlice.reducer

export const { setCurrentPageSet, setCurrentPage } = navbarSlice.actions

export const {
  selectById: selectPageSetById,
  selectEntities: selectPageSets,
} = pagesetAdapter.getSelectors((state) => state.navbar.pageSets)




