import React  from 'react'
import { useNavigate } from "react-router-dom";

import { time_intervals } from '../../utils/timeIntervals'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button'
//import Form from 'react-bootstrap/Form'

import ChartLine from '../graphs/ChartLine'
//import MarDir from '../graphs/MarDir'
import dateFormat from '../../utils/dateFormat'
import { setCopyParameters } from '../engine/engineSlice'
import { useDispatch } from "react-redux";

//FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const SimDetailHeader = ({ sim }) => {
	const dispatch = useDispatch()	
	const navigate = useNavigate();
	const icon = ['fa', 'copy']
	const chartSimple = ['fa', 'chart-simple']


	let sum = 0
	const timeIntervalMs = time_intervals[sim.time_interval]
	let data
	if (sim.closedo.length === 0 || !('resultIncl' in sim.closedo[0])) {
		data = sim.closedo.map( (n) => { 
			//if (!('symbol' in n)) console.log(n)
			sum += n.sell_price/n.buy_price - 1.002
			return {x:n.ts * timeIntervalMs + sim.begin, y: sum } 
		})
	} else {
		const data_obj = {}
		//sim.closedo.sort( (a,b) => a.origIndx - b.origIndx)
		for (var order of sim.closedo) {
			data_obj[order.origIndx] = data_obj[order.origIndx] || {val:0, sumval:sum}
			data_obj[order.origIndx].val += order.resultIncl
			data_obj[order.origIndx].sumval += order.resultIncl
			sum += order.resultIncl
		}
		data = Object.keys(data_obj).map( (key) => { return {x:parseInt(key), y:data_obj[key].sumval, val:data_obj[key].val } })
		/*data = sim.closedo.map( (n) => { 
			sum += n.resultIncl
			return {x:n.origIndx, y: sum, y_good: n.resultIncl } 
		})*/

	}
	const domain = { x:[sim.begin,sim.end] }
	const score = Math.round(sim.result * 100/* /(sim.result_obj.max_closes) */  ) /100

	/*const calcTs_array = (t) => {
		const rar = []
		for (var i = parseInt(t) - timeIntervalMs * 3  ;i <= parseInt(t) + timeIntervalMs * 3  ; i = i + timeIntervalMs) {
			const indx = (i - sim.begin) / timeIntervalMs
			//console.log(indx)
			rar.push({ts:i, eligs: Object.keys(elig[indx]).length})
		}
		return rar		
	}*/
	
	//const [ts, setts]  = useState(ts_init);
	//const [ts_array, setts_array]  = useState(calcTs_array(ts_init) || []);

	/*const selectPoint = (x, y) => {
			const v = Math.round((x/300 * (sim.end - sim.begin) + sim.begin)/timeIntervalMs)* timeIntervalMs
			setts(v)
			setts_array(calcTs_array(v))
			//navigate(`/sims/${sim.header_id}`, { state: { ts } });
		}*/

	/*const handleChanges = (event) => {
		const { id, value } = event.target;
		if (id === "ts") {
			setts(value)
		}
	}*/
	
	const submitTs = (event) => {
		navigate(`/sims/${sim.header_id}`, /*{ state: { ts } }*/);
		event.preventDefault();
	}
	
	const copyParams = (event) => {
		dispatch(setCopyParameters(sim))	
		event.preventDefault();
	}
	
	let h_id = 'ENGINE', tsActive = <></>, pCopy = <></>
	if (sim.header_id) {
		h_id = sim.header_id
		tsActive = <Button onClick={submitTs} variant="secondary"><FontAwesomeIcon icon={chartSimple} /></Button>
		pCopy = <Button onClick={copyParams} variant="secondary"><FontAwesomeIcon icon={icon} /></Button>	
	}
	/*const tsActive = ts ? (
		<Form className="d-inline-flex" >
			<Form.Control  as="select" id={"ts"} value={ts} onChange={handleChanges} >
				{ts_array.map((e) => {
					return <option key={e.ts} value={e.ts}>{dateFormat(e.ts) + ' ' + e.eligs}</option>;
				})}
			</Form.Control>
			<Button onClick={submitTs}>Details</Button>
		</Form>		
		
		
	) : null*/

	return 		(		<Row key={h_id} className="p-1 border">
							<Col lg="5">
							<Row>
								<Table striped bordered hover size="sm">
								  <thead>
									<tr>
									  <th>Id</th>
									  <th>Begin</th>
									  <th>End</th>
									  <th>%</th>
									</tr>
								  </thead>
								  <tbody>
									<tr>
									  <td>{h_id.slice(-5)}</td>
									  <td>{dateFormat(sim.begin)}</td>
									  <td>{dateFormat(sim.end)}</td>
									  <td>{score}</td>
									</tr>
								  </tbody>
								</Table>
							</Row>
							</Col>
							<Col lg="2">
								<Table striped bordered hover size="sm">
								  <tbody>
									<tr>
									  <td>{sim.MINTR}</td>
									  <td>{sim.MINSL}</td>
									</tr>
									<tr>
									  <td>{sim.MAXTR}</td>
									  <td>{sim.MAXSL}</td>
									</tr>
									<tr>
									  <td>{pCopy}</td>
									  <td>{tsActive}</td>
									</tr>
								  </tbody>
								</Table>
							</Col>
							<Col lg="5">
							{/*<Row><MarDir sim={sim}/></Row>*/}
								<Row><ChartLine data={data} domain={domain} /></Row>
							</Col>
				        </Row> )


}

/*<Row><ChartLine data={data} selectPoint={selectPoint} domain={domain} ts={ts} tsmin={parseInt(ts) - 3 * timeIntervalMs} tsmax={parseInt(ts) + 3 * timeIntervalMs}/></Row>*/

export default SimDetailHeader


					
					
