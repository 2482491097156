import React from 'react';
import { useSelector, useDispatch } from "react-redux";

import Button from 'react-bootstrap/Button'

import { pasteCopyParameters, showModal, selectParameters, setEnginePollingInterval } from '../engine/engineSlice'
import { useEngineStartMutation,  useMainSwitchQuery,
  useToggleMainSwitchMutation } from '../api/apiSlice'


//FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const CntxtBtn = ({name}) => {
	const dispatch = useDispatch()

	let buttonSettings = useSelector(state =>
		state.navbar.contextButtons[name]
	);
	let activeParams = useSelector(selectParameters);
	
	const {
		data: mainSwitchStatus
	} = useMainSwitchQuery()	
	//const mainSwitchStatusBool = mainSwitchStatus === '1' ? true : false
	const [toggleMainSwitch] = useToggleMainSwitchMutation();

	const [startEngine] = useEngineStartMutation();
	
	const handleClick = (event) => {
		switch(name) {
		  case 'btnEngineSet':
			dispatch(showModal())
			break;
		  case 'btnEnginePaste':
			dispatch(pasteCopyParameters())
			break;
		  case 'btnEngineStart':
		    startEngine({activeParams})
			dispatch(setEnginePollingInterval({interval:1000}))
		    break;
		  case 'btntoggleMainSwitch':
		    toggleMainSwitch({set:!mainSwitchStatus.DEFAULT})
		    break;	
		  default:
				//do nothing
		}		
	}
	
	const icon = ['fa', buttonSettings.icon]
	
	return (
		<Button size="sm" variant="secondary" onClick={handleClick}><FontAwesomeIcon icon={icon} /></Button>
	)
	
	
}

export default CntxtBtn	
