import React, { /*useEffect,*/ useState } from 'react';
import { /*useLocation,*/ Link /*, useNavigate*/} from 'react-router-dom';

import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Form from 'react-bootstrap/Form'
//import Col from 'react-bootstrap/Col'

import CntxtBtn from './CntxtBtn'

import Logout from '../auth/Logout.js'
import { selectPageSets, /*selectPageSetById,*/ setCurrentPage } from './navbarSlice'
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from '@reduxjs/toolkit'
import { selectCurrentUser } from "../auth/authSlice";
//import logo from '../../logo2.png'

//FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { faUser, faBackwardStep } from '@fortawesome/free-solid-svg-icons'

const Navibar = (props) => {
	const faUser = ['fa', 'user']
	//let location = useLocation();
	const dispatch = useDispatch()
	let user = useSelector(selectCurrentUser);	
  	const currentPageSetId = useSelector(state =>
		state.navbar.currentPageSet
	)

	// const currentTitle = useSelector(state =>
		// state.navbar.title
	// )

	/*const contextButtons = useSelector(state =>
 tate.navbar.contextButtons)
	)*/

	const selectContextButtonsArray = createSelector([state => state.navbar], (a) => Object.keys(a.contextButtons))
	const contextButtonsArray = useSelector(selectContextButtonsArray)

	// const currentPageSet = useSelector((state) =>
		// selectPageSetById(state, currentPageSetId)
	// )

	// const currentPageOffset = useSelector(state =>
		// state.navbar.pageSets?.entities[currentPageSetId]?.currentPage
	// )
	
	const selectPages = createSelector([selectPageSets], (a) => a[currentPageSetId]?.pages || [])
	const pages = useSelector(selectPages)

	/*const pages = useSelector(state =>
		state.navbar.pageSets?.entities[currentPageSetId]?.pages || []
	)*/

	//const selectPage = createSelector([selectPageSets], (a) => a[currentPageSetId]?.pages.find((page) => page.offset === currentPageOffset) || {name:'loading'})
	//const page = useSelector(selectPage)
	/*const page = useSelector(state =>
		state.navbar.pageSets?.entities[currentPageSetId]?.pages.find((page) => page.offset === currentPageOffset) || {name:'loading'}
	)*/

	const [page2, setpage2]  = useState({name:'loading'});

	const handleChanges = (event) => {
		const { id, value } = event.target;
		if (id === "page") {
			setpage2(value)
			dispatch(setCurrentPage({pageSet:currentPageSetId, newPage: value}))
		}
	}

	//const back_icon = ['fa', 'backward-step']
	//const backbutton = (location.pathname === '/') ? null : ( <Navbar.Text className="px-2"><Link to="/" style={{ color: '#FFF'}}  ><FontAwesomeIcon icon={back_icon} /></Link></Navbar.Text>		 )
	//<Nav.Link as={Link} to="/engine" eventKey="2">Engine</Nav.Link>
	const userTitle =  <FontAwesomeIcon icon={faUser} />
	if (user)
	return (
		<Navbar collapseOnSelect expand="md" bg="secondary" variant="dark" sticky="top" className="container-fluid py-0">
			
			<Navbar.Toggle aria-controls="basic-navbar-nav" />
			<Navbar.Collapse id="basic-navbar-nav">	
				<Nav>
					<Nav.Link as={Link} to="/" eventKey="1">Positions</Nav.Link>
					<Nav.Link as={Link} to="/settings" eventKey="2">Settings</Nav.Link>
					<Nav.Link as={Link} to="/sims" eventKey="3">Simulations</Nav.Link>
					<Nav.Link as={Link} to="/report" eventKey="4">Report</Nav.Link>
				</Nav>
			</Navbar.Collapse>
			<Nav className="NavalwaysOn">
				{contextButtonsArray.map( (btn) => {
					return	 <CntxtBtn key={btn} name={btn} />				
				})}				
				<Form className="d-inline-flex px-1" >
					<Form.Control  as="select" id={"page"} value={page2.name} onChange={handleChanges} >
						{pages.map((e) => {
							return <option key={e.offset} value={e.offset}>{e.name}</option>;
						})}
					</Form.Control>
				</Form>					
				<NavDropdown title={userTitle} id="nav-dropdown">
					<NavDropdown.Item as="p" className="Navibar-user">{user}</NavDropdown.Item>
					<NavDropdown.Divider />
					<NavDropdown.Item as="p" className="Navibar-user"><Logout /></NavDropdown.Item>
				</NavDropdown>
			</Nav>			
		</Navbar>
	)
}

export default Navibar;