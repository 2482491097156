import React from 'react'

//victory
import { VictoryChart, VictoryAxis, VictoryLine, VictoryArea, VictoryBar, // Selection
} from 'victory'; 

const ChartLine = ({ data, selectPoint, domain, ts, tsmin, tsmax }) => {
	
	
	/*					{events={[{
						childName: 'all',
						target: 'parent',
						eventHandlers: {
							onClick: (evt) => {
												const {x, y} = Selection.getSVGEventCoordinates(evt);
												selectPoint(x,y)
											}
						}
				    }]}}*/
	
	const maxy = data.reduce( (pv, cv) => cv.y > pv ? cv.y : pv, 0)
	const dataarea = [{x:tsmin, y:maxy},{x:tsmax, y:maxy}]
	return (
		<VictoryChart    
					style={{
						parent: {
						  border: "1px solid #ccc"
						}
					}}
					height={100}
					width={300}
					padding={{top: 3, bottom: 3, left: 0, right: 0}} 
					scale={{ x: "time", y: "linear" }}	
					domain={domain}
		>
			<VictoryAxis style={{ 
				axis: {stroke: "transparent"}, 
				ticks: {stroke: "transparent"},
				tickLabels: { fill:"transparent"}
				}}
			/>	
			<VictoryLine
						style={{
						  data: { stroke: "#5D576B" , strokeWidth: 1},
						}}
						data={data}
			/>
			<VictoryBar
						style={{
						  data: { stroke: "#5D576B" , strokeWidth: 1},
						}}
						data={data}
						y={(d) => d.val }

			/>
			{/*selectionArea*/}
			{!isNaN(tsmin) && <VictoryArea
							style={{
								data: {
									fill: "#c43a31", fillOpacity: 0.2, strokeWidth: 0
								}
							}}
							data={dataarea}
			/>}
		</VictoryChart>
	)
}

export default ChartLine