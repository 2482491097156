const dateFormat = (d) => {
	var date = new Date(d);
	var year = date.getFullYear().toString();
	var month = (date.getMonth() + 1).toString().padStart(2, "0")
	var day = date.getDate().toString().padStart(2, "0")
	var hours = date.getHours().toString().padStart(2, "0")
	var minutes = date.getMinutes().toString().padStart(2, "0")
	//var seconds = date.getSeconds().toString().padStart(2, "0")
	var return_val = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes
	return return_val
}

export default dateFormat