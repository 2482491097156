import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import tsFormat from '../../utils/tsFormat.js'

const iconChooser = (type, direction) => {
		if (type === 'long') {
			if (direction === 'open') {
				return faCaretUp
			} else {
				return faArrowsUpToLine
			}
		} else {
			if (direction === 'open') {
				return faCaretDown
			} else {
				return faArrowsDownToLine
			}
		}
	}

const faCaretUp = ['fa', 'caret-up']
const faCaretDown = ['fa', 'caret-down']
const faArrowsUpToLine = ['fa', 'arrows-up-to-line']
const faArrowsDownToLine = ['fa', 'arrows-down-to-line']
const faArrowsToCircle = ['fa', 'arrows-to-circle']

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({ 
	baseUrl: '/ms',
	prepareHeaders(headers) {
	  return headers;
	},
	credentials: "include"
  }),
  tagTypes: ['User', 'Sims', 'Sim','Series', 'SymbolDetail', 'Stats', 'DropRise', 'Engine', 'PositionList', 'PositionDetail', 'ReportPL', 'MainSwitch', 'CoinSet', 'CoinSettings'],
  endpoints: (builder) => ({
	login: builder.mutation({
      query: (credentials) => ({
        url: "login",
        method: "POST",
        body: credentials,
		headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
				}
      }),
	  providesTags: (result, error, arg) => [
	  {type: 'User', id: result.user},
      ],
    }),
	logout: builder.mutation({
      query: () => ({
        url: "logout",
        method: "POST",
		headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
				}
      }),
	  invalidatesTags: (result, error, arg) => [
        {type: 'User', id: result.user}
      ],
    }),
	getUser: builder.query({
      query: () => `/checkauth`,
      providesTags: (result, error, arg) => {
		  if (result) {
			return [{type: 'User', id: result.user}]
		  } else {
			return [] 
		  }},
    }),
    getSims: builder.query({
      query: ({offset, count}) => `/simheaders?offset=${offset}&count=${count}`,
      providesTags: (result = [], error, arg) => [ 
       ...(result.sims.map(({ meta }) => ({ type: 'Sims', id: meta.header_id }))),
      ],
    }),
	getSim: builder.query({   //just a list of all symbols with a status attribute to sort and filter (status: has open order, etc.
      query: ({header_id, ts, offset, count}) => `/simdetails?header_id=${header_id}&offset=${offset}&count=${count}`,
      providesTags: (result, error, arg) => [
	  ...(result.symbols_sel.map(({ symbol }) => ({ type: 'Sim', id: symbol })))
	  ],
    }),
	/*getSymbolSeries: builder.query({   
      query: ({exchange, symbol, interval, begin, end}) => `/simsymbolseries?exchange=${exchange}&symbol=${symbol}&interval=${interval}&begin=${begin}&end=${end}`,
	  providesTags: (result, error, arg) => [ { type: 'Series', id: arg.symbol } 
	  ],
    }),*/
	getSymbolDetails: builder.query({   
      /*query: ({header_id, detail}) => {
		if (header_id === 'NGINE') {
			return (`/hget?hashkey=${encodeURIComponent('hash:engine:simulations')}&key=${detail.symbol}`)
		} else {
			return (`/hget?hashkey=${encodeURIComponent('hash:simulations:' + header_id)}&key=${detail.symbol}`)
		}
	
	  },*/
	   query: ({header_id, symbol,exchange, interval,begin, end }) => `/symboldetails?header_id=${header_id}&symbol=${symbol}&exchange=${exchange}&interval=${interval}&begin=${begin}&end=${end}`,
	  // {counter:0, 
	  //  hist:{
	  //  	{type:'none', priceL_O: [], priceL_C: [], priceS_O: [], priceS_C: [], elig:1 }
	  //    }, 
	  //   all_open_lims: [{Open_time: 123.., limit: 99.999},   ], 
	  //   trades: [ {trigger: 'OPEN',type:'open', price: 99.999, qty: 12, side: 'buy', ts: 123...},    ] 
	  //	}
	  /*transformResponse(response, meta, arg) {
		const l_summTrades = []
		let seq = 1
		let saldo = 0
		let qoh = 0
		const FEE = .002
		let sortedTrades = []
		if (response.trades && response.trades.length > 0) {
			sortedTrades = response.trades.sort( (a,b) => a.ts - b.ts)
		}
        for (var trade of sortedTrades ) {
			saldo += (trade.side === 'buy' ? -1 : 1) * trade.price * trade.qty - FEE * trade.price * trade.qty
			const val = (trade.side === 'buy' ? -1 : 1) * trade.price * trade.qty
			qoh += (trade.side === 'buy' ? 1 : -1) * trade.qty
			const sqty = (trade.side === 'buy' ? 1 : -1) * trade.qty
			l_summTrades.push( {...trade, seq, saldo, qoh, sqty,val } )
			seq += 1
		}		
		const retObj = {...response}
		retObj.summTrades = l_summTrades
		const cL = arg.detail.centerLine 
		const bracketsetter = []
		//const lastClose = data[data.length-1].Close
		bracketsetter.push({icon:faArrowsToCircle, price:cL, type:'unordered', dist: 0,
								amt:null, erroraction:null, lastaction:null, exec:null, profit:null, color:'white'})
		let color = 'yellow'
		if (arg.detail?.priceL_O) {
			for (let limit of arg.detail?.priceL_O) {
			bracketsetter.push({icon:faCaretUp ,price:limit, type:'Long', dist: ((limit/cL - 1)*100).toFixed(2),amt:null, erroraction:null, lastaction:null, exec:null, profit:null, color })
			}
		}
		if (arg.detail?.priceS_O) {
			for (let limit of arg.detail?.priceS_O) {
			bracketsetter.push({icon:faCaretDown ,price:limit, type:'Short', dist: ((limit/cL - 1)*100).toFixed(2),amt:null, erroraction:null, lastaction:null, exec:null, profit:null, color})
			}
		}
		bracketsetter.sort( (a,b) => b.price - a.price);
		retObj.bracketsetter = bracketsetter
		return retObj
      },*/
      providesTags: (result, error, arg) =>  [ { type: 'SymbolDetail', id: arg.symbol } 
	  ], 
    }),
	getStats: builder.query({   
      query: ({exchange, begin, end, interval, quote}) => `/kcdstats3?exchange=${exchange}&begin=${begin}&end=${end}&interval=${interval}&quote=${quote}`,
      providesTags: (result, error, arg) =>  [ { type: 'Stats', id: arg.quote } ]
    }),
	getDropRise: builder.query({   
      query: ({exchange, begin, end, interval, quote, threshold}) => `/droprise?exchange=${exchange}&begin=${begin}&end=${end}&interval=${interval}&quote=${quote}&threshold=${threshold}`,
      providesTags: (result, error, arg) =>  [ { type: 'DropRise', id: arg.quote } ]
    }),
	engineStart: builder.mutation({   
		query: (body) => ({
			url: "enginestart",
			method: "POST",
			body,
			headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
					 }
			
		}),
	  invalidatesTags: ['Series', 'SymbolDetail', 'Engine',/* 'Position', 'PositionDetail'*/],
    }),
	engineStatus: builder.query({   
      query: ({offset,count}) => `/enginestatus?offset=${offset}&count=${count}`,
     /* providesTags: (result, error, arg) =>  
	  [...(result.result.ranking.map(({ symbol }) => ({ type: 'Engine', id: symbol })))]*/
	  providesTags: (result, error, arg) => ['Engine']
	  //console.log(result)
    }),
	openPos: builder.mutation({   
		query: (body) => ({
			url: "openpos",
			method: "POST",
			body,
			headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
					 }
			
		}),
	  invalidatesTags: (result, error, arg) => [{type: 'Series',id:arg.symbol},{type: 'SymbolDetail',id:arg.symbol},{type: 'PositionDetail',id:arg.symbol}, 'PositionList'],
    }),	
	positions: builder.query({   
      query: ({offset,count,exchange}) => `/positions?offset=${offset}&count=${count}&exchange=${exchange}`,
	  /*providesTags: (result, error, arg) => [ 
	  ...(result.result.map(({symbol}) => ({ type: 'Position', id:symbol }))) 
	  ],*/
	  providesTags: (result, error, arg) => [ 'PositionList' ],
    }),
	/*position: builder.query({   
      query: ({exchange,symbol}) => `/position?exchange=${exchange}&symbol=${symbol}`,
	  transformResponse(response, arg) {
		  const retObj = {...response}
		  // {result,details,botstep,orderActions}
		  const cL = response.result.centerLine
		  const bracketsetter = []
		  const actionsetter = []
		  if (response.orderActions.length > 0 ) {
				response.orderActions.forEach( (oA) => {
					actionsetter.push({icon: iconChooser(oA.type,oA.direction), 
									   ts:tsFormat(oA.ts), dist: ((oA.price/cL - 1)*100).toFixed(2), 
									   price:oA.price,
									   amt:Math.round(oA.qty*oA.price),
									   action:oA.action, 
									   status: oA.posdetailAction[0] + oA.origin + oA.subActionorder[0] + oA.actionStatus + oA.pdStatus,
									   error:oA.error,
									   id:oA.id, 
									   qty:oA.qty, 
									   actionStatus:oA.actionStatus,
									   pdStatus:oA.pdStatus
					}) 
				})
			}
			//const botstepsetter = []
			//const lastClose = data[data.length-1].Close
			bracketsetter.push({icon:faArrowsToCircle, price:cL, type:response.result.status, dist: 0,
									amt:null, erroraction:null, lastaction:null, exec:null, profit:null, color:'white'})
			//console.log(detail.centerLine, {price:detail.centerLine})
			//bracketsetter.push({icon:faTag,price:lastClose, type:'Current', dist: ((lastClose/cL - 1)*100).toFixed(2),
			//						amt:null, erroraction:null, lastaction:null, exec:null, profit:null, color:'red', ts: data.slice(-1)[0]?.Open_time })
		
			//const lastTs = data[data.length-1].Open_time
			let color
			//let absPos = 0
			let openPosPresent = false
			if (response.result.status != 'none') {
				openPosPresent = true
				//const type = 'long' / 'short'
				//const direction = 'open' / 'close'
				let deta = response.details
				let avgCost = null
				if (['open','xcenter','close'].includes(response.result.status)) {
					const totVol = deta.filter( (posdetail) => posdetail.direction === 'open').reduce( (pv,cv) => pv + parseFloat(cv.vol_exec), 0 ) 
					avgCost = deta.filter( (posdetail) => posdetail.direction === 'open').reduce( (pv,cv) => pv + parseFloat(cv.cost) + parseFloat(cv.fee), 0 ) / totVol
				}
				if (['pre-open'].includes(response.result.status)) {
					deta = deta.filter( (d) => d.direction === 'open')
				}
				switch (response.result.status) {
					case 'pre-open':
						color = 'orange'
						break
					case 'open':
						color = 'red'
						break
					case 'xcenter':
						color = 'purple'
						break
					default:
						color = 'white'
				}
				for (var posdetail of deta) {
					let profit = null
					if (posdetail.direction === 'close' && avgCost) {
						if (posdetail.type === 'long') {
							profit = null //(Math.round((lastClose/avgCost - 1) * 1000)/10).toFixed(1)
						} else {
							profit = null //(Math.round((avgCost/lastClose - 1) * 1000)/10).toFixed(1)
						}
					} 
					let eAct = null, lAct = null
					if (posdetail?.errorAction?.length > 0) eAct = posdetail.errorAction[0]
					if (posdetail?.action?.length > 0) lAct = posdetail.action[0]
					bracketsetter.push({icon:iconChooser(posdetail.type,posdetail.direction),price:posdetail.price, type:posdetail.type, dist: ((posdetail.price/cL - 1)*100).toFixed(2), amt:Math.round(posdetail.price*posdetail.vol_exec), erroraction:eAct, lastaction:lAct, exec:Math.round(posdetail.vol_exec/posdetail.vol*100), profit, color , ts: posdetail.opentm})
					//absPos = absPos + profit * posdetail.vol
				}
			} 
			bracketsetter.sort( (a,b) => b.price - a.price);
			retObj.bracketsetter = bracketsetter
			retObj.actions = actionsetter
			//retObj.absPos = absPos
			retObj.openPosPresent = openPosPresent
			return retObj
	  },
	   providesTags: (result, error, arg) => [
		{ type: 'PositionDetail', id: arg.symbol } 
	   ],
    }),*/
	holdPos: builder.mutation({   
		query: (body) => ({
			url: "holdpos",
			method: "POST",
			body,
			headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
					 }
			
		}),
	  invalidatesTags: (result, error, arg) => [{type: 'PositionDetail',id:arg.symbol}],
    }),
	updateAction: builder.mutation({   
		query: (body) => ({
			url: "updateorderaction",
			method: "POST",
			body,
			headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
					 }
			
		}),
	  invalidatesTags: (result, error, arg) => [{type: 'PositionDetail',id:arg.symbol}],
    }),	
	reportPL: builder.query({   
      query: ({count, offset}) => `/reportpl?count=${count}&offset=${offset}`,
	  providesTags: (result, error, arg) => [ 'ReportPL' ],
    }),	
	cancelPre: builder.mutation({   
		query: (body) => ({
			url: "cancelpreopen",
			method: "POST",
			body,
			headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
					 }
			
		}),
	  invalidatesTags: (result, error, arg) => [{type: 'PositionDetail',id:arg.symbol}],
    }),	
	mainSwitch: builder.query({   
      query: () => `/getmasterstatus?hashkey=${encodeURIComponent('hash:headers:coinset:active')}&key=DEFAULT`,
	  providesTags: (result, error, arg) => [ 'MainSwitch' ],
    }),
	toggleMainSwitch: builder.mutation({   
		query: (body) => ({
			url: "masterswitch",
			method: "POST",
			body,
			headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
					 }
		}),
	  invalidatesTags: (result, error, arg) => ['MainSwitch'],
    }),
	coinSet: builder.query({   
      query: ({count, offset}) => `/coinset?count=${count}&offset=${offset}`,
	  providesTags: (result, error, arg) => [ 'CoinSet' ],
    }),
	coinSettings: builder.query({   
      query: ({exchange,symbol}) => `/coinsettings?exchange=${exchange}&symbol=${symbol}`,
	  providesTags: (result, error, arg) => [
		{ type: 'CoinSettings', id: arg.symbol } 
	   ],
    }),
	coinStatus: builder.mutation({   
		query: (body) => ({
			url: "coinstatus",
			method: "POST",
			body,
			headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
					 }
		}),
	  invalidatesTags: (result, error, arg) => [{type: 'CoinSettings',id:arg.symbol}],
    }),
	setCoinSettings: builder.mutation({   
		query: (body) => ({
			url: "setcoinsettings",
			method: "POST",
			body,
			headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
					 }
		}),
	  invalidatesTags: (result, error, arg) => [{type: 'CoinSettings',id:arg.symbol}],
    }),
	phaseOut: builder.mutation({   
		query: (body) => ({
			url: "posphaseout",
			method: "POST",
			body,
			headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
					 }
		}),
	  invalidatesTags: (result, error, arg) => [{type: 'PositionDetail',id:arg.symbol}],
    }),
  }),
})

export const {
  useLoginMutation,
  useLogoutMutation,
  useGetUserQuery,
  useGetSimsQuery,
  useGetSimQuery,
  //useGetSymbolSeriesQuery,
  useGetSymbolDetailsQuery,
  useGetStatsQuery,
  useGetDropRiseQuery,
  useEngineStartMutation,
  useEngineStatusQuery,
  useOpenPosMutation,
  usePositionsQuery,
  //usePositionQuery,
  useHoldPosMutation,
  useReportPLQuery,
  useUpdateActionMutation,
  useCancelPreMutation,
  useMainSwitchQuery,
  useToggleMainSwitchMutation,
  useCoinSetQuery,
  useCoinSettingsQuery,
  useCoinStatusMutation, 
  useSetCoinSettingsMutation,
  usePhaseOutMutation
} = apiSlice
