import React, { useEffect, useState } from 'react';
import {  Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { useGetUserQuery } from "./features/api/apiSlice";
//Local imports
import PrivateRoute from './utils/PrivateRoute'
import Login from './features/auth/Login'
//import Main from './Main'
import SimsList from './features/sims/SimsList'
import Navibar from './features/navbar/Navibar'
import SimDetails from './features/sims/SimDetails'
import Engine from './features/engine/EngineHeader'
import Positions from './features/positions/Positions'
import Report from './features/report/Report'
import Settings from './features/settings/leanSettings'

import {library} from '@fortawesome/fontawesome-svg-core';
import { faPaste, faSliders, faCopy, faBackwardStep, faPlay, faStop, faChartSimple, faClock, faHashtag, faEuroSign, faMoneyCheckDollar, faList, faCaretUp, faCaretDown, faArrowsUpToLine, faArrowsDownToLine, faTag, faArrowsToCircle, faPause, faChevronDown, faUser, faXmark, faToggleOn, faToggleOff, faArrowTrendDown } from '@fortawesome/free-solid-svg-icons';

library.add(faPaste, faSliders, faCopy, faBackwardStep, faPlay, faStop, faChartSimple, faClock, faHashtag, faEuroSign, faMoneyCheckDollar, faList, faCaretUp, faCaretDown, faArrowsUpToLine, faArrowsDownToLine, faTag, faArrowsToCircle, faPause, faChevronDown, faUser, faXmark, faToggleOn, faToggleOff, faArrowTrendDown )
//console.log(library)
//</Route>
function App() {
	const push = useNavigate();	
	const {data: user, /*error*/} = useGetUserQuery();
	
	let location = useLocation();
	const [arrivalLocation]  = useState(location.pathname);
	/*useEffect(() => {	
		setArrivalLocation(location.pathname)
	}, []);	*/
	
	useEffect(() => {
		if (user) push(arrivalLocation);
	}, [user, arrivalLocation]);
//<Route path="/engine" element={<PrivateRoute><Engine /></PrivateRoute>}/>
  return (
    <div className="App">
		<Navibar />
		<Routes>
			<Route path="/login" element={<Login />}/>
			<Route path="/sims" element={<PrivateRoute><SimsList /></PrivateRoute>}/>
			<Route path="/sims/:HeaderId" element={<PrivateRoute><SimDetails /></PrivateRoute>}/>
			<Route path="/settings" element={<PrivateRoute><Settings /></PrivateRoute>}/>
			<Route path="/" element={<PrivateRoute><Positions /></PrivateRoute>}/>
			<Route path="/report" element={<PrivateRoute><Report /></PrivateRoute>}/>
		</Routes>
    </div>
  );
}

export default App;
