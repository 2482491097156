



function formatTimestamp(timestamp) {
    const currentDate = new Date();
    const inputDate = new Date(timestamp);
    
    // Check if the input date is today
    if (
        inputDate.getDate() === currentDate.getDate() &&
        inputDate.getMonth() === currentDate.getMonth() &&
        inputDate.getFullYear() === currentDate.getFullYear()
    ) {
        // If it's today, return time in hh:mm format
        const hours = inputDate.getHours().toString().padStart(2, '0');
        const minutes = inputDate.getMinutes().toString().padStart(2, '0');
        return `${hours}:${minutes}`;
    } else {
        // If it's before today, return date in dd/MM format
        const day = inputDate.getDate().toString().padStart(2, '0');
        const month = (inputDate.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
        //const year = inputDate.getFullYear();
        return `${day}/${month}`;
    }
}

export default formatTimestamp