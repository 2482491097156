import {
  createSlice /*, current */
} from '@reduxjs/toolkit'

const simSlice = createSlice({
  name: 'sim',
  initialState: {pollingIntervals:{}, labelPrices:{}},
  reducers: {
	setPositionPollingInterval: (
      state,
       { payload } 
    ) => {
		state.pollingIntervals[payload.symbol] = {pollingInterval: payload.interval, pollingAttempts: payload.attempts}
    },
	setLabelPrice: (
      state,
       { payload } 
    ) => {
		state.labelPrices[payload.pos_id] = payload.labelPrice
    },
  },

})

export default simSlice.reducer

export const { setPositionPollingInterval, setLabelPrice } = simSlice.actions

//define selectors
export const selectPollingIntervalByPosSymbol = (symbol) => (state) => {
  if (state.sim.pollingIntervals[symbol]) return state.sim.pollingIntervals[symbol];
  return 15*60*1000
};

export const selectLabelPriceByPosId = (pos_id) => (state) => {
  if (state.sim.labelPrices[pos_id]) return state.sim.labelPrices[pos_id];
  return null
};




