import React from 'react'
import { useGetSimQuery } from '../api/apiSlice'
import usePageProvider from '../navbar/pageProvider'
import { useParams, /*useLocation*/  } from "react-router-dom";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import SimDetailHeader from './SimDetailHeader'
import SymbolDetails from './SymbolDetails'


const SimDetails = () => {
  //let { state } = useLocation();
  let { HeaderId } = useParams();

  const { count, offset } = usePageProvider(5, 'getSim', 'Coin/EUR')
  //const ts = state.ts
  const {
    data: simdetails,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetSimQuery({header_id:HeaderId, offset ,count})
  let content
  if (isLoading) {
    content = <Container><Row><Col>Loading...</Col></Row></Container>
  } else if (isSuccess) {
    content = <><Container>
				<SimDetailHeader sim={simdetails.header} />
			  </Container>
			  <Container>
				{simdetails.symbols_sel.map( (detail) => {
					return (<SymbolDetails detail={detail} HeaderId={HeaderId} key={detail.symbol} simheader={simdetails.header} />)
				})}
			  </Container></>
  } else if (isError) {
    content = <Container><Row><Col>{error.toString()}</Col></Row></Container>
  }

  return (
    <div className="sims-list">
      {content}
    </div>
  )
}

export default SimDetails;