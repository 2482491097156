import React, { useState } from 'react'

//import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table';
//FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import tsFormat from '../../utils/tsFormat.js'

const ReportDetails = ({week}) => {
	const expandIcon = ['fa', 'chevron-down']

	const [showDetails, setShowDetails]  = useState('hidden');
	
	const toggleShowDetails = (event) => {
		if (showDetails === 'hidden') setShowDetails('')
		if (showDetails === '') setShowDetails('hidden')
	}
	//symbol, pos_open,last_close,type,status,P_L ,fee,avg_buy_price,avg_sell_price
	return (
		<section>
			<Row><Col>W{week.week}</Col>
				 <Col>{week.TOTAL}</Col>
				 <Col>{week.COUNT}</Col>
				 <Col><Button size="sm" onClick={toggleShowDetails} variant="secondary"><FontAwesomeIcon icon={expandIcon} /></Button></Col>
			</Row>
			<Row className={showDetails}>
				<Table striped bordered hover size="sm">
					<thead>
						<tr>
						  <th>Symbol</th>
						  <th>Open</th>
						  <th>Closed</th>
						  <th>Type</th>
						  <th>Status</th>
						  <th>Result</th>
						  <th>Fee</th>
						</tr>
					</thead>
					<tbody>
						{week.details.map( (pos) => {
							return <tr key={pos.symbol + pos.ts} >
									<td>{pos.symbol}</td>
								<td>{tsFormat(pos.ts)}</td>
								<td>{tsFormat(pos.last_close_unix * 1000)}</td>
								<td>{pos.type}</td>
								<td>{pos.status}</td>
								<td>{pos.P_L}</td>
								<td>{pos.fee}</td></tr>
						})}
					</tbody>
				</Table>
			</Row>
		</section>
	  )
}  
 
export default ReportDetails;